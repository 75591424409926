import { render, staticRenderFns } from "./CadastralsTable.vue?vue&type=template&id=861d3fa6"
import script from "./CadastralsTable.vue?vue&type=script&lang=js"
export * from "./CadastralsTable.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {CompositionBackgroundCard: require('/app/node_modules/@estaid/ui/dist/runtime/molecules/Composition/CompositionBackgroundCard.vue').default,Copy: require('/app/node_modules/@estaid/ui/dist/runtime/atoms/Copy/Copy.vue').default,SortableTable: require('/app/components/SortableTable/SortableTable.vue').default,CompositionLayoutSingle: require('/app/node_modules/@estaid/ui/dist/runtime/molecules/Composition/CompositionLayoutSingle.vue').default,Composition: require('/app/node_modules/@estaid/ui/dist/runtime/molecules/Composition/Composition.vue').default})
