
import { getPropertyAreaQuery } from "~/helpers/apollo/apollo-property-query-helpers.js";
import PropertyBuildings from "../components/mainproperty/PropertyBuildings.vue";
import CondoBuildings from "../components/condo/CondoBuildings.vue";
import BuildingOnForeignPlotBuildings from "../components/buildingonforeignplot/BuildingOnForeignPlotBuildings.vue";

export default {
  title() {
    return `${this.$t("MENU_BUILDINGS")}`;
  },

  apollo: {
    property: getPropertyAreaQuery,
  },

  components: {
    PropertyBuildings,
    CondoBuildings,
    BuildingOnForeignPlotBuildings,
  },

  computed: {
    loading: function () {
      return this.property == null || this.$apollo.queries.property.loading;
    },
    isProperty: function () {
      return this.property.plots != null;
    },
    isCondo: function () {
      return this.property.condo != null;
    },
    isBuildingOnForeignPlot: function () {
      return this.property.buildingOnForeignPlot != null;
    },
  },
};
