import { render, staticRenderFns } from "./PropertyBuildings.vue?vue&type=template&id=0a602702"
import script from "./PropertyBuildings.vue?vue&type=script&lang=js"
export * from "./PropertyBuildings.vue?vue&type=script&lang=js"
import style0 from "./PropertyBuildings.vue?vue&type=style&index=0&id=0a602702&prod&module=true&lang=css"




function injectStyles (context) {
  
  this["$style"] = (style0.locals || style0)

}

/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {WidgetKeyValue: require('/app/components/Widget/WidgetKeyValue.vue').default,ChartTreemap: require('/app/components/Chart/ChartTreemap.vue').default,WidgetBlank: require('/app/components/Widget/WidgetBlank.vue').default,WidgetLayoutRowCellThirds: require('/app/components/Widget/WidgetLayoutRowCellThirds.vue').default,WidgetLayoutRowSpacer: require('/app/components/Widget/WidgetLayoutRowSpacer.vue').default,TabBoxItem: require('/app/node_modules/@estaid/ui/dist/runtime/molecules/TabBox/TabBoxItem.vue').default,TabBox: require('/app/node_modules/@estaid/ui/dist/runtime/molecules/TabBox/TabBox.vue').default,SortableTablColumnToggleButton: require('/app/components/SortableTable/SortableTablColumnToggleButton.vue').default,CompositionSpacer: require('/app/node_modules/@estaid/ui/dist/runtime/molecules/Composition/CompositionSpacer.vue').default,Icon: require('/app/node_modules/@estaid/ui/dist/runtime/atoms/Icon/Icon.vue').default,Copy: require('/app/node_modules/@estaid/ui/dist/runtime/atoms/Copy/Copy.vue').default,PushButton: require('/app/node_modules/@estaid/ui/dist/runtime/atoms/PushButton/PushButton.vue').default,WidgetLayoutRowCellDouble: require('/app/components/Widget/WidgetLayoutRowCellDouble.vue').default,WidgetLayoutRowCellSingle: require('/app/components/Widget/WidgetLayoutRowCellSingle.vue').default,WidgetLayout: require('/app/components/Widget/WidgetLayout.vue').default,Composition: require('/app/node_modules/@estaid/ui/dist/runtime/molecules/Composition/Composition.vue').default})
