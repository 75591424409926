// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".controls_0TRNa{display:flex}.containerEnd_dbzO4,.containerStart_Q4zy1{align-items:center;display:flex}.containerStart_Q4zy1{width:100%}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"controls": "controls_0TRNa",
	"containerEnd": "containerEnd_dbzO4",
	"containerStart": "containerStart_Q4zy1"
};
module.exports = ___CSS_LOADER_EXPORT___;
