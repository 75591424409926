
import { createBuildingsSheet, createUnitsSheet, createFacilitiesSheet, createCondosSheet, writeSheetsToXSLXAndSave } from "~/helpers/download-helpers.js";

import BuildingsTable from "../BuildingsTable.vue";
import UnitsTable from "../UnitsTable.vue";
import SortableTablColumnToggleButton from "~/components/SortableTable/SortableTablColumnToggleButton.vue";

import TechnicalFacilitiesTable from "../TechnicalFacilitiesTable.vue";
import CondosTable from "../CondosTable.vue";
import { getSelectedTabFromUrl } from "~/plugins/common-fns.js";
import { getPropertyCondosByBfeNumberQuery } from "~/helpers/apollo/apollo-property-query-helpers.js";
import { calculateBuildingArea } from "~/helpers/area-calculation-helpers.js";
import { getUsageBreakdownByArea } from "~/helpers/usage-helpers.js";

export default {
  title() {
    return `${this.$t("MENU_BUILDINGS")}`;
  },

  components: {
    BuildingsTable,
    UnitsTable,
    CondosTable,
    TechnicalFacilitiesTable,
    SortableTablColumnToggleButton,
  },
  props: {
    property: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      modalActiveTable: "",
      active: getSelectedTabFromUrl(this),
    };
  },

  apollo: {
    condos: getPropertyCondosByBfeNumberQuery,
  },

  computed: {
    totalBuildingArea: function () {
      return calculateBuildingArea(this.property);
    },

    usageBreakdown: function () {
      const maxUsageTypes = 4;
      const usage = getUsageBreakdownByArea(this.property, maxUsageTypes, this.$t("USAGE_TYPE_OTHER"));
      return {
        series: [
          {
            data: Object.values(usage).map((u) => {
              return {
                x: u.usageText,
                y: u.usageArea,
              };
            }),
          },
        ],
      };
    },
  },

  methods: {
    setModalActiveTable(component) {
      this.modalActiveTable = component;
    },

    download() {
      const buildings = createBuildingsSheet(this.property.buildings, this.$i18n.locale);
      const units = createUnitsSheet(this.property.units, this.$i18n.locale);
      const condos = createCondosSheet(this.condos, this.$i18n.locale);
      const technicalFacilities = createFacilitiesSheet(this.property.technicalFacilities, this.$i18n.locale);
      writeSheetsToXSLXAndSave([buildings, units, condos, technicalFacilities], "Download");
    },

    amplitudeLogEvent(triggerEvent) {
      triggerEvent = "View buildings " + triggerEvent;
      this.$amplitude.track({ event_type: triggerEvent });
    },

    selectTabAndLogAmplitudeEvent(id, event) {
      this.$router.push({
        path: this.$route.path,
        query: {
          activeTab: id,
        },
      });

      this.active = id;

      this.$amplitude.track({ event_type: `View buildings ${event}` });
    },
  },
};
