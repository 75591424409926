
import { sortByAddress } from "~/helpers/sort-helpers.js";

export default {
  inheritAttrs: false,

  props: {
    property: {
      type: Object,
      default: null,
    }
  },

  data() {
    return {
      noOfUnits: 0
    };
  },

  methods: {
    sortByAddress
  },
};
