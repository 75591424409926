// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".controls_DoDTI{display:flex}.containerEnd_-XFmP,.containerStart_9KnFT{align-items:center;display:flex}.containerStart_9KnFT{width:100%}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"controls": "controls_DoDTI",
	"containerEnd": "containerEnd_-XFmP",
	"containerStart": "containerStart_9KnFT"
};
module.exports = ___CSS_LOADER_EXPORT___;
