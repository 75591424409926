
export default {
  inheritAttrs: false,

  props: {
    property: {
      type: Object,
      default: null,
    },
  },

  computed: {
    technicalFacilities() {
      return this.property?.technicalFacilities;
    },
  },
};
