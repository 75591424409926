
export default {
  inheritAttrs: false,

  props: {
    property: {
      type: Object,
      default: null,
    }
  }
};
