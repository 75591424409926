
import { sortByAddress } from "~/helpers/sort-helpers.js";

export default {
  inheritAttrs: false,

  props: {
    property: {
      type: Object,
      default: null,
    },
  },

  computed: {
    buildings() {
      return this.property?.buildings || [];
    },
  },

  methods: {
    sortByAddress,
  },
};
