
import { sortByAddress } from "~/helpers/sort-helpers.js";

export default {
  inheritAttrs: false,

  props: {
    addresses: {
      type: Array,
      default: () => []
    }
  },

  methods: {
    sortByAddress,

    by_address_hack(a, b) {
      return sortByAddress(
        { address: a },
        { address: b }
      )
    }
  },
}
