
import { sortByAddress } from "~/helpers/sort-helpers.js";

export default {
  inheritAttrs: false,

  props: {
    condos: {
      type: Array,
      default: null,
    },

    loading: {
      type: Boolean,
      required: true,
    },
  },

  methods: {
    sortByAddress: sortByAddress,
  },
};
