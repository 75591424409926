
import { createCondosBuildingsSheet, writeSheetsToXSLXAndSave } from "~/helpers/download-helpers.js";
import SortableTablColumnToggleButton from "~/components/SortableTable/SortableTablColumnToggleButton.vue";

export default {
  components: {
    SortableTablColumnToggleButton,
  },

  props: {
    property: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {};
  },

  computed: {
    unit: function () {
      return this.property.condo.unit;
    },
  },

  methods: {
    download() {
      const buildingOverview = createCondosBuildingsSheet([this.unit], this.$i18n.locale);
      writeSheetsToXSLXAndSave([buildingOverview], "Download");
    },
  },
};
